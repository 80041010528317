<template>
  <div class="container">
    <div class="tab">
      <span
        :class="{ active: validCodeBonus == '' }"
        @click="changevalidCodeBonus('')"
        >全部</span
      >
      <span
        :class="{ active: validCodeBonus == 16 }"
        @click="changevalidCodeBonus(16)"
        >已付款</span
      >
      <span
        :class="{ active: validCodeBonus == 17 }"
        @click="changevalidCodeBonus(17)"
        >已收货</span
      >
      <span
        :class="{ active: validCodeBonus == 13 }"
        @click="changevalidCodeBonus(13)"
        >失效</span
      >
    </div>
    <div class="content" id="con">
      <div v-if="orderList.length > 0">
        <div class="order_item" v-for="item in orderList" :key="item.order_id">
          <div class="title">
            <div class="icon">
              <span class="jd" v-if="item.platform_type == 'jd'">京东</span>
              <span class="tb" v-if="item.platform_type == 'tb'">京东</span>
              <span class="glay" v-if="item.validCodeBonus == 13">失效</span>
              <span class="glay" v-if="item.validCodeBonus == 16">已付款</span>
              <span class="glay" v-if="item.validCodeBonus == 17">已收货</span>
            </div>
            <div class="reward">
              奖励：<span>￥{{ item.bonus_fee }}</span>
            </div>
          </div>
          <div class="goods">
            <img :src="item.avatar" alt="" class="goods_img" />
            <div class="goods_detail">
              <p>{{ item.skuName }}</p>
              <span>订单金额：¥{{ (item.price * item.skuNum).toFixed(2)}}</span>
              <span>订单编号：{{ item.order_nu }}</span>
              <span>下单日期：{{ item.orderTime }}</span>
            </div>
          </div>
        </div>
        <div class="loadmore" v-if="hasMore&&loadMore" ><img src="../assets/loading.png" />加载中</div>
        <!---->
      </div>
      <div class="order_no" v-if="orderList.length <= 0">暂无相关数据</div>
    </div>
    <div class="showToast" v-if="showToast">
      <div class="showToast_con">{{ showToast }}</div>
    </div>
  </div>
</template>

<script>
// import 'lib-flexible'

import { resetRem } from "@/assets/rem.js";
export default {
  data() {
    return {
      validCodeBonus: "",
      page: 1,
      limit: 10,
      orderList: [],
      hasMore: true,
      loadMore:false,
      showToast:""
    };
  },
  created() {
    resetRem();
  },
  beforeCreate () {
      document.title = "拉新奖励订单"
  },
  mounted() {
    this.getOrder(this.page);
    window.addEventListener("scroll", this.onScroll);
  },
  methods: {
    changevalidCodeBonus(e) {
      this.page = 1;
      this.validCodeBonus = e;
      this.orderList = [];
      this.getOrder(this.page);
    },
    getOrder(page) {
      var that = this;
      var data = {
        page: page,
        limit: that.limit,
        validCodeBonus: that.validCodeBonus,
      };
      that.axios.post("/app/order/userDirectOrderBonus", data).then((res) => {
          that.loadMore=false;
        if (res.data.code == 200) {
          var orderList = that.orderList.concat(res.data.data.list);
          that.orderList = orderList;
          that.page = res.data.data.page;
          that.hasMore = res.data.data.hsaMore;
        } else if (res.data.code == -1) {
          that.showToast = "登录失效";
          setTimeout(function () {
            that.showToast = "";
            window.localStorage.setItem("token", "");
            that.$router.push({
              path: "/loginApp",
            });
          }, 2000);
        } else {
          that.showToast = res.data.msg;
          setTimeout(function () {
            that.showToast = "";
          }, 2000);
        }
      });
    },
    onScroll() {
      let innerHeight = document.querySelector("#con").clientHeight;
      let outerHeight = document.documentElement.clientHeight;
      let scrollTop = document.documentElement.scrollTop;
      if (outerHeight + scrollTop === innerHeight) {
        if (this.hasMore) {
          this.loadMore=true
          this.getOrder(this.page + 1, 2);
        }
      }
    },
  },
};
</script>
<style scoped>
.container {
  width: 10rem;
  margin: 0 auto;
  height: auto;
  min-height: 100%;
  background: rgb(246, 246, 246);
  position: relative;
}
.tab {
  width: 8.77333rem;
  height: 1.3333rem;
  margin: 0 auto;
  display: flex;
  background: rgb(246, 246, 246);
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
  position: fixed;
  top: 0;
  left: 50%;
  margin-left:-4.386665rem
}
.tab span {
  display: block;
  height: 1.3333rem;
  line-height: 1.3333rem;
  font-size: 0.3733rem;
  color: #999999;
}
.tab span.active {
  color: #bf9375;
  font-weight: 700;
}
.order_item {
  width: 8.77333rem;
  margin: 0 auto 0.32rem;
  background: #fff;
  border-radius: 0.16rem;
}
.content {
  padding-top: 1.3333rem;
  padding-bottom: 0.48rem;
  min-height: 100%;
  box-sizing: border-box;
}
.order_item .title {
  height: 1.0667rem;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 0 0.48rem;
  box-sizing: border-box;
}
.order_item .icon span {
  display: inline-block;
  border: 1px solid #999999;
  border-radius: 2px;
  margin-right: 0.16rem;
  padding: 0 0.16rem;
  height: 0.48rem;
  box-sizing: border-box;
  font-size: 0.2933rem;
  line-height: 0.44rem;
  color: #999;
  font-weight: bold;
}
.order_item .icon .jd {
  border: 1px solid #ff2a2a;
  color: #ff2a2a;
}
.order_item .icon .tb {
  border: 1px solid #ff7f0a;
  color: #ff7f0a;
}
.order_item .reward {
  font-size: 0.32rem;
  color: #bf9375;
}
.order_item .reward span {
  font-weight: bold;
}
.goods {
  padding: 0 0.48rem 0.5333rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}
.goods img {
  width: 2.6667rem;
  height: 2.6667rem;
  margin-right: 0.2133rem;
  border-radius: 4px;
}
.goods .goods_detail {
  flex: 1;
}
.goods .goods_detail p {
  height: 1.0667rem;
  font-size: 0.3733rem;
  line-height: 0.5333rem;
  color: #333333;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  margin: 0;
}
.goods .goods_detail span {
  display: block;
  margin-top: 0.1067rem;
  color: #999999;
  font-size: 0.2933rem;
  line-height: 0.4267rem;
}
.order_no {
  background: #ffffff;
  border-radius: 4px;
  width: 8.77333rem;
  height: 2.72rem;
  margin: 0 auto;
  text-align: center;
  line-height: 2.72rem;
  font-size: 0.32rem;
  color: #999999;
}
.showToast {
  width: 10rem;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
}
.showToast_con {
  height: 1.333rem;
  line-height: 1.333rem;
  background: #4c4c4c;
  border-radius: 0.667rem;
  padding: 0 1.28rem;
  font-family: PingFang-SC-Regular;
  font-size: 0.3733rem;
  color: #ffffff;
  letter-spacing: 0;
}
.loadmore {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  color: #999;
    font-size: 0.2933rem;
}
.loadmore img {
  width: 0.6667rem;
  margin-right: 0.12rem;
  animation: myfirst 0.5s infinite;
}
@keyframes myfirst {
  from {
    transform: rotate(0);
    -ms-transform: rotate(0); /* Internet Explorer */
    -moz-transform: rotate(0); /* Firefox */
    -webkit-transform: rotate(0); /* Safari 和 Chrome */
    -o-transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
    -ms-transform: rotate(360deg); /* Internet Explorer */
    -moz-transform: rotate(360deg); /* Firefox */
    -webkit-transform: rotate(360deg); /* Safari 和 Chrome */
    -o-transform: rotate(360deg);
  }
}
</style>